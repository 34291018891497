import AWS from 'aws-sdk';

AWS.config.update({
  correctClockSkew: true,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  stsRegionalEndpoints: 'regional', // Substitua pela sua região
});

// Cria um novo objeto S3
const s3 = new AWS.S3();

async function ListFiles(prefix: string, index: string | undefined) {
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET as string,
    Prefix: prefix,
    StartAfter: index ? index : '',
  };

  try {
    const data = await s3.listObjectsV2(params).promise();

    const signedUrls = data?.Contents?.map((object) => {
      if (object.Key) {
        const signedUrl = s3.getSignedUrl('getObject', {
          Bucket: process.env.REACT_APP_AWS_BUCKET,
          Key: object.Key,
          Expires: 7200,
        });

        return {
          Key: object.Key,
          url: signedUrl,
        };
      }
      return null;
    });
    return signedUrls?.filter(Boolean);
    // const files = data?.Contents?.map(file => file.Key);
    // return data.Contents;
  } catch (error) {
    console.error('Erro ao listar arquivos:', error);
    throw error;
  }
}

export default ListFiles;
