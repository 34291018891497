import React, { useEffect } from 'react';
import styles from './VideosHome.module.css';
import { useFileContext } from '../../useFileContext';
import { useLocation, useNavigate } from 'react-router-dom';
import TituloAssociado from '../Helper/TituloAssociado';
import Button from '../Forms/Button';
import introVideo from '../../Assets/tutoriais-intro.mp4';

const VideosHome = () => {
  const url = `https://www.youtube.com/embed/O81KxbXRZnk?autoplay=1&rel=0&modestbranding=1`;
  const { setCategoria } = useFileContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setCategoria([]);
  }, []);

  return (
    <section className={styles.containerPrincipal}>
      <div className={styles.divTitulo}>
        <TituloAssociado titulo="Tutoriais na Prática" />
        {pathname.includes('area-gerencial') && (
          <Button
            onClick={() => navigate('novo-video')}
            descricao="+ novo video"
            classe="third"
          />
        )}
      </div>
      <div className={styles.videoDesc}>
        {/* <iframe
          className={styles.video}
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video player"
        ></iframe> */}
        <video
          className={styles.video}
          src={introVideo}
          controls
          autoPlay
          loop
          playsInline
        ></video>
        <p className={styles.desc}>
          Sejam bem vindos a nossa nova área de{' '}
          <span>tutoriais na prática</span>, aqui você poderá encontrar{' '}
          <span>todo o tipo de conteúdo</span> para{' '}
          <span>sanar suas dúvidas</span> relativas a nossa{' '}
          <span>
            aplicação web, preenchimento de guias, acesso aos convênios
          </span>
          , etc. Assista ao <span>vídeo introdutório</span> ao lado sobre essa
          nova área da aplicação para <span>entender melhor</span> como irá
          funcionar e como utilizá-la.
        </p>
      </div>
    </section>
  );
};

export default VideosHome;
