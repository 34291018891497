import AWS from 'aws-sdk';

type ObjetoS3 = {
  url_logo?: string;
  nome_arquivo: string;
  url: string;
};

AWS.config.update({
  correctClockSkew: true,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  stsRegionalEndpoints: 'regional', // Substitua pela sua região
});

// Cria um novo objeto S3
const s3 = new AWS.S3();

async function getFromS3Signed(data: ObjetoS3[]) {
  try {
    const signedUrls = data?.map((object) => {
      if (object.nome_arquivo || object.url_logo) {
        const signedUrl = s3.getSignedUrl('getObject', {
          Bucket: process.env.REACT_APP_AWS_BUCKET,
          Key: object.nome_arquivo
            ? object.nome_arquivo
            : decodeURIComponent(
                object.url_logo
                  ? object.url_logo.replace(
                      'https://site-amai-producao.s3.sa-east-1.amazonaws.com/',
                      '',
                    )
                  : '',
              ),
          Expires: 7200,
        });

        return {
          ...object,
          Key: object.nome_arquivo,
          url: signedUrl,
        };
      }
      return null;
    });
    return signedUrls?.filter(Boolean);
    // const files = data?.Contents?.map(file => file.Key);
    // return data.Contents;
  } catch (error) {
    console.error('Erro ao listar arquivos:', error);
    throw error;
  }
}

export default getFromS3Signed;
