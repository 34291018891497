import {
  Link,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import styles from './VideosConv.module.css';
import { useEffect, useState } from 'react';
import { GET_VIDEOS, GET_VIDEOS_CONV } from '../../api';
import { useUserContext } from '../../UserContext';
import { useFileContext } from '../../useFileContext';
import TituloAssociado from '../Helper/TituloAssociado';
import path from 'path';
import VideoFrameList from '../Videos/VideoFrameList';
import VideoTitle from '../Videos/VideoTitle';
import VideoDescription from '../Videos/VideoDescription';
import { useAssociadoContext } from '../../useAssociadoContext';

type Video = {
  id?: number;
  url?: string;
  categoria_id?: number | string | undefined;
  convenio_id?: number | string | undefined;
  status?: boolean;
  descricao?: string;
  titulo?: string;
};

const VideosConv = () => {
  const { convenio } = useAssociadoContext();
  const { token, tokenGer } = useUserContext();
  const { pathname } = useLocation();
  const { videos, setVideos } = useFileContext();
  const [pesquisar, setPesquisar] = useState('');
  const [pesquisados, setPesquisados] = useState<Video[]>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setPesquisar(query);
    const handleSearch = (query: string) => {
      const filtered = videos?.filter((video) => {
        const lowerCaseQuery = query.toLowerCase();
        return Object.values(video).some(
          (valor) =>
            typeof valor === 'string' &&
            valor.toLocaleLowerCase().includes(lowerCaseQuery),
        );
      });

      setPesquisados(filtered);
    };

    if (query.length > 2) {
      handleSearch(query);
    } else {
      handleSearch('');
    }
  };

  useEffect(() => {
    async function getVideos() {
      const { url, options } = GET_VIDEOS_CONV(token ? token : tokenGer, {
        convenio_id: convenio[0].id,
      });

      const response = await fetch(url, options);
      const json = await response.json();
      if (!response.ok) throw new Error('Erro ao trazer vídeos');
      setVideos(json);
      setPesquisados(json);
    }
    getVideos();
  }, []);

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <TituloAssociado titulo={`${convenio[0]?.nome} - Videos`} />
      <input
        className={styles.procurar}
        type="text"
        placeholder="Busque o vídeo que deseja..."
        onChange={handleChange}
        value={pesquisar}
      />
      {pesquisados && pesquisados.length > 0 ? (
        pesquisados.map((video: Video, index) => (
          <Link to={`${video.titulo}`}>
            <div key={index} className={styles.videoContainer}>
              <VideoFrameList url={video.url as string} />
              <div className={styles.dadosContainer}>
                <VideoTitle title={video.titulo as string} />
                <VideoDescription description={video.descricao as string} />
              </div>
            </div>
          </Link>
        ))
      ) : (
        <p>Esse convênio ainda não tem vídeos.</p>
      )}
    </section>
  );
};

export default VideosConv;
