import AWS from 'aws-sdk';
import { useAssociadoContext } from '../../useAssociadoContext';
import { useFileContext } from '../../useFileContext';

interface FileUploadResponse {
  Location: string;
  Bucket: string;
  Key: string;
  key: string;
  // inclua outras propriedades conforme necessário
}

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_REGION, // Substitua pela sua região
  stsRegionalEndpoints: 'regional',
});

const s3 = new AWS.S3();

async function UploadFiles(
  files: File[],
  cnpj: string,
  folder: string,
): Promise<FileUploadResponse[] | undefined | unknown[]> {
  const uploadPromises = files.map(async (file, index) => {
    if (file) {
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET as string,
        Key: `${folder}/${cnpj.replace(/\D/g, '')}${file.name}`, // Substitua conforme necessário
        Body: file,
        ContentType: file.type || 'application/octet-stream',
        // ACL: 'public-read', // Substitua conforme necessário
      };
      return new Promise((resolve, reject) => {
        s3.upload(params, (err: any, data: any) => {
          if (err) {
            reject(`Erro ao fazer upload do arquivo ${index}: ${err}`);
          } else {
            resolve(data);
          }
        });
      });
    }
  });

  try {
    const results: FileUploadResponse[] | unknown[] = await Promise.all(
      uploadPromises,
    );
    return results;
  } catch (error) {
    console.error('Erro ao enviar arquivos para o S3:', error);
  }
}

export default UploadFiles;
