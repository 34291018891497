import { useEffect, useState } from 'react';
import styles from './DadosCadastrais.module.css';
import { useUserContext } from '../../UserContext';
import { useAssociadoContext } from '../../useAssociadoContext';
import { useNavigate } from 'react-router-dom';
import {
  ALTERA_CADASTRO_MEDICO,
  ALTERA_CADASTRO_MEDICO_USER,
  ATUALIZAR_CADASTRO_ASS,
  GET_CLINICA,
  GET_PROFISSIONAL,
} from '../../api';
import TituloAssociado from '../Helper/TituloAssociado';
import ClinicaForm from '../AreaGerencial/ClinicaForm';
import Button from '../Forms/Button';
import Modal from 'react-modal';
import { MedicoFormTypes, useMedicoContext } from '../../useMedicoContext';
import MedicoForm from './MedicoForm';
import { useFileContext } from '../../useFileContext';

const DadosCadastrais = () => {
  const { token, setLoading, data } = useUserContext();
  const { medico, setMedico, formData, setFormData } = useMedicoContext();
  const { selectedOptions } = useAssociadoContext();
  const { formDataClinica } = useAssociadoContext();
  const dateOnly =
    typeof medico?.dat_nascimento === 'string'
      ? medico.dat_nascimento.split('T')[0]
      : '';
  const [ano, mes, dia] = dateOnly.split('-');
  const dataNasc = dia + '/' + mes + '/' + ano;
  const [initialValues, setInitialValues] = useState({});
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [clinica, setClinica] = useState<any | undefined>();
  const navigate = useNavigate();

  const fecharModal = () => {
    setEmailSent(false);
    navigate('/area-associado');
  };

  useEffect(() => {
    if (formData?.especialidades !== selectedOptions) {
      setFormData((prev: any) => ({
        ...prev,
        especialidades: selectedOptions,
      }));
    }
  }, [selectedOptions]);
  async function atualizaCadastro() {
    try {
      const { url, options } = ATUALIZAR_CADASTRO_ASS(
        token,
        formDataClinica ? formDataClinica : {},
      );
      const response = await fetch(url, options);
      const { message } = await response.json();
      if (!response.ok) throw new Error(message);
      setMensagem(message);
      setEmailSent(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function atualizaMedico() {
    try {
      setLoading(true);
      setLoading(true);
      const novoFormData = { ...formData, especialidades: selectedOptions } as
        | MedicoFormTypes
        | undefined;

      setFormData(novoFormData);

      const { url, options } = ALTERA_CADASTRO_MEDICO_USER(
        token,
        novoFormData as object,
      );
      const response = await fetch(url, options);
      const { message } = await response.json();
      if (!response.ok) throw new Error(message);
      setMensagem(message);
      setEmailSent(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function getClinica() {
      try {
        // setClinica({});
        // setMedico({});
        const { url, options } = GET_CLINICA(token);
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok)
          throw new Error('Erro ao tentar trazer os dados da clínica');
        setClinica(json);
      } catch (error) {
        console.log(error);
      }
    }
    async function getProfissional() {
      try {
        // setClinica({});
        // setMedico({});
        const { url, options } = GET_PROFISSIONAL(token);
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok)
          throw new Error('Erro ao tentar trazer os dados do Médico');
        setMedico(json);
      } catch (error) {
        console.log(error);
      }
    }
    if (!data?.pessoa_fisica) getClinica();
    else getProfissional();
  }, []);

  useEffect(() => {
    if (clinica) {
      setInitialValues({
        id_clinica: clinica.id_clinica,
        nom_clinica: clinica.nom_clinica,
        num_cnpj: clinica.num_cnpj,
        des_endereco: clinica.des_endereco,
        bairro: clinica.bairro,
        num_cep: clinica.num_cep,
        data: clinica.dataFormatada,
        num_inscricao: clinica.num_inscricao,
        des_email: clinica.des_email,
        nom_fantasia: clinica.nom_fantasia,
        telefones: clinica.telefones,
        especialidades: clinica.especialidades,
        num_reg_conselho: clinica.num_reg_conselho,
        cidade: clinica.cidade,
        uf: clinica.uf,
        email_clinica: clinica.email_clinica,
        whatsapp: clinica.whatsapp,
        insta_clinica: clinica.insta_clinica,
        rt_clinica: clinica.rt_clinica,
        cpf_rt: clinica.cpf_rt,
        num_conselho_rt: clinica.num_conselho_rt,
        email_rt: clinica.email_rt,
        telefone_rt: clinica.telefone_rt,
        nome_fat: clinica.nome_fat,
        telefone_fat: clinica.telefone_fat,
        email_fat: clinica.email_fat,
        resp_cadastro: clinica.resp_cadastro,
        tel_resp_cadastro: clinica.tel_resp_cadastro,
        ind_status: clinica.ind_status,
        cod_clinica: clinica.cod_clinica,
      });
    }
    if (medico && data.pessoa_fisica) {
      setInitialValues({
        id_medico: medico?.id_medico,
        nom_medico: medico?.nom_medico,
        dat_nascimento: dataNasc,
        num_cpf: medico?.num_cpf,
        sgl_conselho: medico?.sgl_conselho,
        num_crm: medico?.num_crm,
        especialidades: medico?.especialidades,
        num_identidade: medico?.num_identidade,
        nom_orgao_expedidor: medico?.nom_orgao_expedidor,
        naturalidade: medico?.naturalidade,
        des_uf: medico?.des_uf,
        des_sexo_medico: medico?.des_sexo_medico,
        des_endereco: medico?.des_endereco,
        des_estado_civil: medico?.des_estado_civil,
        cidade: medico?.cidade,
        num_cep: medico?.num_cep,
        tel_fixo: medico?.tel_fixo,
        tel_celular: medico?.tel_celular,
        des_email: medico?.des_email,
        whatsapp: medico?.whatsapp,
        instagram: medico?.instagram,
        resp_cadastro: medico?.resp_cadastro,
        tel_resp_cadastro: medico?.tel_resp_cadastro,
        uf_conselho: medico?.uf_conselho,
        uf_endereco: medico?.uf_endereco,
      });
    }
  }, [clinica, medico]);

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      {clinica && (
        <TituloAssociado
          titulo={
            clinica.nom_fantasia ? clinica.nom_fantasia : clinica.nom_clinica
          }
        />
      )}
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>{mensagem}</p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divForm}>
        {initialValues && clinica && (
          <ClinicaForm initialValues={initialValues} />
        )}
        {initialValues && data?.pessoa_fisica && (
          <MedicoForm initialValues={initialValues} />
        )}
        {/* <div className={styles.divDocumentos}>
          <h2>Documentos</h2>
          {docs && docs.length === 0 ? (
            <p>Nenhum documento encontrado para essa clínica</p>
          ) : (
            <div className={styles.divLinks}>
              {docs.map((item: any, index: any) => (
                <Link key={index} to={item.url} target="_blank">
                  {tipos[item.tipo - 1]}
                </Link>
              ))}
            </div>
          )}
        </div> */}
        <div className={styles.botoes}>
          <Button
            descricao="cancelar"
            classe="cancel"
            onClick={() => navigate(-1)}
          />
          <Button
            descricao="salvar"
            classe="submit"
            onClick={data?.pessoa_fisica ? atualizaMedico : atualizaCadastro}
          />
        </div>
      </div>
    </section>
  );
};

export default DadosCadastrais;
