import { useEffect, useState } from 'react';
import styles from './HomeAssociado.module.css';
import TituloAssociado from '../Helper/TituloAssociado';
import ListFiles from '../ServerSide/findFromS3';
import { Link } from 'react-router-dom';
import Head from '../Helper/Head';
import { useUserContext } from '../../UserContext';

// interface FileProps {
//   Key: string;
//   url: string;
// }

const HomeAssociado = () => {
  // const [circularesFilt, setCircularesFilt] = useState<FileProps[]>();
  const { setCirculares, circularesFilt, setCircularesFilt } = useUserContext();
  // const [filesFim, setFilesFim] = useState<any>()

  useEffect(() => {
    async function fetchArquivos() {
      try {
        let filesFim: any = [];
        let files: any = await ListFiles('circulares/', '');
        while (files && files?.length >= 1000) {
          filesFim.push(...files);
          const lastFile: any = files[files.length - 1].Key;
          files = await ListFiles('circulares/', lastFile);
          filesFim.push(...(await files));
        }
        if (filesFim) {
          const mappedFiles = filesFim.map((file: any) => {
            return { Key: file.Key, url: file.url }; // Mapeando os objetos para o formato FileProps
          });
          setCirculares(
            /* prevState => [...prevState ?? [], ... */ mappedFiles /* ] */,
          );

          mappedFiles.sort((a: any, b: any) => {
            // Extrai os anos dos nomes dos arquivos (assumindo que o ano está na posição 6 e 7)
            const yearA = parseInt(a.Key.substr(14, 4));
            const yearB = parseInt(b.Key.substr(14, 4));

            // Extrai os números dos nomes dos arquivos (assumindo que o número começa na posição 10)
            const numberA = parseInt(a.Key.substr(11, 3));
            const numberB = parseInt(b.Key.substr(11, 3));

            // Compara primeiro pelo ano
            if (yearA !== yearB) {
              return yearB - yearA; // Ordem decrescente (do mais recente para o mais antigo)
            }
            // Se os anos forem iguais, compara pelo número
            return numberB - numberA; // Ordem crescente (do menor para o maior)
          });
          setCircularesFilt((prevState) => [
            ...(prevState ?? []),
            ...mappedFiles,
          ]);
        }
      } catch (error) {
        console.error('Erro ao buscar arquivos:', error);
      }
    }
    fetchArquivos();
  }, []);

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Últimas Circulares"
        description="Área do associado"
      />
      <TituloAssociado titulo="últimas circulares" />
      <div className={styles.divCirculares}>
        {circularesFilt?.map((item, index) =>
          index === 0 || index >= 11 ? (
            ''
          ) : (
            <Link key={index} to={item.url} target="blank">
              {'Nº ' +
                item.Key.replace('circulares/', '')
                  .replace('.pdf', '')
                  .replace(/\+/g, ' ')}
            </Link>
          ),
        )}
      </div>
    </section>
  );
};

export default HomeAssociado;
