import React, { useEffect } from 'react';
import styles from './MedicoForm.module.css';
import Input from '../Forms/Input';
import { MedicoFormTypes, useMedicoContext } from '../../useMedicoContext';
import { useAssociadoContext } from '../../useAssociadoContext';
import SelectEsp from '../Forms/SelectEsp';
import ConselhoSelect from '../Forms/ConselhoSelect';
import { useLocation } from 'react-router-dom';

interface InitialValuesTypes {
  initialValues: any;
}
const MedicoForm = ({ initialValues }: InitialValuesTypes) => {
  const conselhos = [
    'COREN',
    'CRAS',
    'CRBIO',
    'CRBM',
    'CREF',
    'CREFITO',
    'CRESS',
    'CRF',
    'CRFA',
    'CRM',
    'CRMV',
    'CRN',
    'CRO',
    'CRP',
    'CRTR',
    'CRV',
    'OUT',
  ];
  const { formData, setFormData } = useMedicoContext();
  const { setSelectedOptions, selectedOptions, especialidades } =
    useAssociadoContext();
  const { pathname } = useLocation();

  function filtrarEsp() {
    const espSplit = initialValues.especialidades?.split(';');
    const espTrat = espSplit?.map((item: any) => {
      return item.replace(/^ /, '').toUpperCase();
    });
    const espFiltred = especialidades
      ?.filter((item: any) => espTrat?.includes(item.des_especialidade))
      .map((item: any) => ({
        value: item.id_especialidade,
        label: item.des_especialidade,
      }));
    if (selectedOptions.length < 1) {
      setSelectedOptions(espFiltred);
    }
  }
  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      especialidades: selectedOptions,
    }));
  }, [selectedOptions]);

  useEffect(() => {
    filtrarEsp();
  }, [especialidades, initialValues]);

  useEffect(() => {
    setFormData(initialValues);
  }, [initialValues]);

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    if (formData) setFormData({ ...formData, [name]: value });
  };

  return (
    <div className={styles.form}>
      <Input
        label="Nome Completo"
        type="text"
        name="nom_medico"
        onChange={handleInputChange}
        value={formData?.nom_medico}
        classe="spanAll"
        maxLength={50}
      />
      <Input
        label="Data nascimento"
        type="text"
        name="dat_nascimento"
        onChange={handleInputChange}
        value={formData?.dat_nascimento}
        classe="col3"
        maxLength={10}
        disabled={true}
      />
      <div className={`${styles.wrapper} col3`}>
        <label className={styles.label} htmlFor="conselho-select">
          Conselho
        </label>
        <select
          className={styles.select}
          id="conselho-select"
          name="sgl_conselho"
          onChange={handleInputChange}
          value={formData?.sgl_conselho}
        >
          <option value="">Selecione...</option>
          {conselhos.map((conselho) => (
            <option key={conselho} value={conselho}>
              {conselho}
            </option>
          ))}
        </select>
      </div>
      <Input
        label="Nº Conselho"
        type="text"
        name="num_crm"
        onChange={handleInputChange}
        value={formData?.num_crm}
        classe="col3"
        maxLength={10}
        disabled={true}
      />
      <Input
        label="UF do Conselho"
        type="text"
        name="uf_conselho"
        onChange={handleInputChange}
        value={formData?.uf_conselho}
        classe="col2"
        maxLength={2}
        disabled={false}
      />
      <SelectEsp />
      {/* <Input
        label="Especialidades (Se mais de uma especialidade, separá-las por ; )"
        type="text"
        name="especialidades"
        onChange={handleInputChange}
        value={formData?.especialidades}
        classe="col5"
        maxLength={300}
        disabled={true}
      /> */}
      <Input
        label="RG"
        type="text"
        name="num_identidade"
        onChange={handleInputChange}
        value={formData?.num_identidade}
        classe="col2"
        maxLength={30}
        disabled={true}
      />
      <Input
        label="Emissor"
        type="text"
        name="nom_orgao_expedidor"
        onChange={handleInputChange}
        value={formData?.nom_orgao_expedidor}
        classe="col1"
        maxLength={20}
        disabled={true}
      />
      <Input
        label="CPF"
        type="text"
        name="num_cpf"
        onChange={handleInputChange}
        value={formData?.num_cpf}
        classe="col2"
        disabled={true}
      />
      <Input
        label="Naturalidade"
        type="text"
        name="naturalidade"
        onChange={handleInputChange}
        value={formData?.naturalidade}
        classe="col2"
        disabled={true}
      />
      <Input
        label="UF"
        type="text"
        name="des_uf"
        onChange={handleInputChange}
        value={formData?.des_uf}
        classe="col1"
        maxLength={2}
        disabled={true}
      />
      <Input
        label="Sexo"
        type="text"
        name="des_sexo_medico"
        onChange={handleInputChange}
        value={formData?.des_sexo_medico}
        classe="col1"
        maxLength={1}
        disabled={true}
      />
      <Input
        label="Estado Civil"
        type="text"
        name="des_estado_civil"
        onChange={handleInputChange}
        value={formData?.des_estado_civil}
        classe="col2"
        maxLength={20}
      />
      <Input
        label="Endereço"
        type="text"
        name="des_endereco"
        onChange={handleInputChange}
        value={formData?.des_endereco}
        maxLength={150}
        classe="spanAll"
      />
      <Input
        label="Cidade"
        type="text"
        name="cidade"
        onChange={handleInputChange}
        value={formData?.cidade}
        classe="col2"
        maxLength={100}
      />
      <Input
        label="UF"
        type="text"
        name="uf_endereco"
        onChange={handleInputChange}
        value={formData?.uf_endereco}
        classe="col1"
        maxLength={2}
      />
      <Input
        label="CEP"
        type="text"
        name="num_cep"
        onChange={handleInputChange}
        value={formData?.num_cep}
        classe="col2"
        maxLength={8}
      />
      <Input
        label="Telefone Fixo"
        type="text"
        name="tel_fixo"
        onChange={handleInputChange}
        value={formData?.tel_fixo}
        classe="col3"
      />
      <Input
        label="Celular"
        type="text"
        name="tel_celular"
        onChange={handleInputChange}
        value={formData?.tel_celular}
        classe="col3"
      />
      <Input
        label="E-mail"
        type="text"
        name="des_email"
        onChange={handleInputChange}
        value={formData?.des_email}
        classe="col5"
        maxLength={50}
      />
      <Input
        label="Contato Whatsapp"
        type="text"
        name="whatsapp"
        onChange={handleInputChange}
        value={formData?.whatsapp}
        classe="col3"
        maxLength={20}
      />
      <Input
        label="Instagram"
        type="text"
        name="instagram"
        onChange={handleInputChange}
        value={formData?.instagram}
        classe="col3"
        maxLength={300}
      />
      {!pathname.includes('dados-cadastrais') && (
        <>
          <Input
            label="Nome - Responsável Cadastro"
            type="text"
            name="resp_cadastro"
            onChange={handleInputChange}
            value={formData?.resp_cadastro}
            classe="col6"
            disabled={true}
          />
          <Input
            label="Telefone - Responsável Cadastro"
            type="text"
            name="tel_resp_cadastro"
            onChange={handleInputChange}
            value={formData?.tel_resp_cadastro}
            classe="col5"
            disabled={true}
          />
        </>
      )}
    </div>
  );
};

export default MedicoForm;
