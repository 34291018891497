import React from 'react';
import styles from './Convenios.module.css';
import convenios from '../../Assets/convenios-img.jpg';
import Button from '../Forms/Button';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const Convenios = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <section
      className={`${styles.containerPrincipal} ${inView ? 'animeRight' : ''}`}
      ref={ref}
    >
      <div className={styles.divPrincipal}>
        <div className={styles.divTextos}>
          <h1>Cuidamos do essencial</h1>
          <p>
            A AMAI é composta de uma equipe de{' '}
            <span>profissionais qualificados</span>, onde{' '}
            <span>simplificamos</span> o processo complexo de faturamento,
            permitindo que os profissionais da saúde{' '}
            <span>foquem no que realmente importa:</span> cuidar dos seus
            pacientes.
          </p>
          <Link to="/convenios">
            <Button classe="third" descricao="mais informações" />
          </Link>
        </div>
        <img
          src={convenios}
          alt="Um homem com um estetoscópio e vários símbolos relacionados à saude."
        />
      </div>
    </section>
  );
};

export default Convenios;
